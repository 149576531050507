import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react'
import './ChatForm.scss'
import { I18N, AXIOS, CHANNEL_TYPES, MODALS } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Brand, Channel } from '@web/_types'
import { isRep, isHCP, canCommunicate } from '@web/_guards'
import TextareaAutosize from 'react-autosize-textarea'
import Button from '@web/js/components/Button'
import ArrowForward from '@web/common/img/ArrowForward'
import SamplesIcon from '@web/common/img/SamplesIcon'
import { ChatService } from '@web/_services'
import { ChatContext } from '@web/js/context/ChatContext'
import ContactIcon from '@web/common/img/ContactIcon'
import { ModalContext } from '@web/js/context/ModalContext'

interface ChatFormProps {
  channel: Channel
  setFormLoaded: (formLoaded: boolean) => void
}

interface Sample {
  type: string
  id: string
  name: string
  description: string | null
  message: string | null
}

interface BrandSamples {
  brand: Brand
  id: string
  samples: Sample[]
}

const ChatForm: React.FC<ChatFormProps> = ({ channel, setFormLoaded }) => {
  const controller = new AbortController()
  const { t } = useTranslation(I18N.namespaces.web)

  const { queueMessage } = useContext(ChatContext)
  const { showModal } = useContext(ModalContext)

  const [brandSamples, setBrandSamples] = useState<BrandSamples[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [disabledSubmit, setDisabledSubmit] = useState(true)

  const channelRef = useRef(channel)
  const chatInputRef = useRef<HTMLTextAreaElement>(null)
  const disabledForm =
    !channel.active ||
    channel.type === CHANNEL_TYPES.partial ||
    (!!channel.connection &&
      isRep(channel.connection) &&
      channel.connection.inactive)

  useEffect(() => {
    return () => {
      controller.abort(AXIOS.canceled)
    }
  }, [])

  useEffect(() => {
    if (chatInputRef.current) chatInputRef.current.focus()
  }, [chatInputRef.current])

  useEffect(() => {
    if (chatInputRef.current)
      chatInputRef.current.addEventListener('keydown', _handleKeyDown)

    return () => {
      if (chatInputRef.current)
        chatInputRef.current.removeEventListener('keydown', _handleKeyDown)
    }
  }, [chatInputRef])

  useEffect(() => {
    //prevent reload of form if loaded and channel id has not changed
    if (
      !isLoading &&
      channel.id === channelRef.current.id &&
      channel.active === channelRef.current.active
    )
      return

    setFormLoaded(false)
    channelRef.current = channel
    loadBrandSamples()
    setIsLoading(false)
  }, [channel])

  const _handleKeyDown = (event: KeyboardEvent) => {
    //if in IME compose mode (japanese, etc)
    if (event.key === 'Enter' && event.isComposing) return

    if (event.key === 'Enter' && !event.shiftKey) {
      handleSendMessage(event)
    }
  }

  const loadBrandSamples = async () => {
    if (
      channel.active &&
      !!channel.connection &&
      isRep(channel.connection) &&
      !channel.connection.inactive
    ) {
      try {
        const repId = channel.connection.id
        const getSamplesResponse = await ChatService.getSamples(
          repId,
          controller.signal
        )
        const data = getSamplesResponse.data
        const repBrandIds = isRep(channel.connection)
          ? channel.connection.brandIds
          : []
        const hasSamples = !!Object.keys(data.content).length

        if (hasSamples) {
          const brandIds = _.intersection(
            repBrandIds,
            Object.keys(data.content)
          )
          const dossier = data.dossier
          const brandSamples = _.map(brandIds, (id) => {
            const brand = dossier.brands[id]
            const samples = data.content[id]
            return { brand, id, samples }
          })

          setBrandSamples(brandSamples)
        } else {
          setBrandSamples([])
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setBrandSamples([])
    }

    setFormLoaded(true)
  }

  const handleSendMessage = (e: React.SyntheticEvent | KeyboardEvent) => {
    e.preventDefault()
    const body = (chatInputRef.current as HTMLTextAreaElement).value
    if (!body) return

    queueMessage({ channelId: channel.id, body })
    setTimeout(() => {
      if (chatInputRef.current) {
        chatInputRef.current.value = ''
        chatInputRef.current.focus()
        setDisabledSubmit(true)
      }
    }, 0)
  }

  const setTypingOn = _.debounce(
    () => ChatService.isTyping(channel.id, true),
    2000,
    { leading: true, trailing: false }
  )

  const setTypingOff = _.debounce(
    () => ChatService.isTyping(channel.id, false),
    2000,
    { leading: false, trailing: true }
  )

  const debounceSetTypingOn = useCallback(setTypingOn, [])
  const debounceSetTypingOff = useCallback(setTypingOff, [])

  const typingHandler = (event: React.FormEvent<HTMLTextAreaElement>) => {
    if (disabledSubmit && !!event.currentTarget.value) {
      setDisabledSubmit(false)
    } else if (!disabledSubmit && !event.currentTarget.value) {
      setDisabledSubmit(true)
    }

    debounceSetTypingOn()
    debounceSetTypingOff()
  }

  const renderShareContactBtn = () => {
    if (
      !(
        channel.connection &&
        isHCP(channel.connection) &&
        canCommunicate(channel.connection)
      )
    )
      return null

    return (
      <div className="chat-form--funcs">
        <Button
          style="primary"
          outline={true}
          size="sm"
          id="share-contact-btn"
          classes="btn-action-bar"
          onClick={() =>
            showModal({
              name: MODALS.SHARE_CONTACT_MODAL,
              data: {
                channelId: channel.id,
              },
            })
          }
          iconFront={<ContactIcon />}
        >
          {t('shareAContact')}
        </Button>
      </div>
    )
  }

  const renderSamplesMaterialsBtn = () => {
    if (!brandSamples.length) return null

    return (
      <div className="chat-form--funcs">
        <Button
          style="primary"
          outline={true}
          size="sm"
          id="request-samples-btn"
          classes="btn-action-bar"
          onClick={() =>
            showModal({
              name: MODALS.REQUEST_SAMPLES_MODAL,
              data: {
                channelId: channel.id,
                brandSamples,
              },
            })
          }
          iconFront={<SamplesIcon />}
        >
          {t('samplesMaterials')}
        </Button>
      </div>
    )
  }

  return (
    <div className="chat-form">
      {renderSamplesMaterialsBtn()}

      {renderShareContactBtn()}

      <form noValidate onSubmit={handleSendMessage}>
        <div className="chat-form--text-input">
          <TextareaAutosize
            rows={1}
            maxRows={8}
            ref={chatInputRef}
            onChange={typingHandler}
            disabled={disabledForm}
            placeholder={channel.active ? t('typeSomething') : ''}
            style={{ minHeight: '32px' }}
          />
        </div>
        <div className="chat-form--submit">
          <Button
            style="primary"
            size="md"
            type="submit"
            disabled={disabledForm || disabledSubmit}
            outline={!channel.active}
            icon={<ArrowForward />}
          />
        </div>
      </form>
    </div>
  )
}

export default ChatForm
