import React, { useContext, useState } from 'react'
import './RequestSamplesModal.scss'
import { I18N } from '@web/_constants'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Checkbox from '@web/js/components/Checkbox'
import { ChatContext } from '@web/js/context/ChatContext'
import { Brand } from '@web/_types'
import BrandCard from '../../components/BrandCard'
import Modal, { ModalBody, ModalFooter } from '../../components/Modal'
import { ModalContext } from '@web/js/context/ModalContext'

interface RequestSamplesModalProps {
  brandSamples: BrandSamples[]
  channelId: string
}

interface Sample {
  type: string
  id: string
  name: string
  description: string | null
  message: string | null
}

interface BrandSamples {
  brand: Brand
  id: string
  samples: Sample[]
}

const RequestSamplesModal: React.FC<RequestSamplesModalProps> = ({
  brandSamples,
  channelId,
}) => {
  const { t } = useTranslation(I18N.namespaces.web)
  const { queueMessage } = useContext(ChatContext)
  const { hideModal } = useContext(ModalContext)
  const [sampleRequests, setSampleRequests] = useState<string[]>([])

  const sendSampleRequest = () => {
    const filteredSamples = _.chain(brandSamples)
      .map('samples')
      .flatten()
      .filter((brandSample) => _.includes(sampleRequests, brandSample.id))
      .value()

    let body = t('samplesMaterialsColon')
    _.each(filteredSamples, (filteredSample) => {
      body += `\n • ${filteredSample.message}`
    })

    console.log({ channelId, body, sampleIds: sampleRequests })

    queueMessage({ channelId, body, sampleIds: sampleRequests })
    clearSampleRequestForm()
  }

  const clearSampleRequestForm = () => {
    setSampleRequests([])
    hideModal()
  }

  const handleChange = (id: string, checked: boolean) => {
    if (checked) {
      setSampleRequests([...sampleRequests, id])
    } else {
      setSampleRequests(_.without(sampleRequests, id))
    }
  }

  return (
    <Modal
      title={`${t('samplesMaterials')}`}
      closeable={true}
      size="lg"
      onClose={clearSampleRequestForm}
    >
      <ModalBody id="samples-request">
        {brandSamples.map((brandSample, brandIndex) => {
          return (
            <div className="brand-samples-list" key={brandSample.id}>
              <BrandCard
                brand={brandSample.brand}
                connectionId={channelId}
                category="SAMPLES_SELECTOR"
              />

              {brandSample.samples.map((sample, sampleIndex) => {
                return (
                  <Checkbox
                    key={sample.id}
                    autoFocus={brandIndex === 0 && sampleIndex === 0}
                    id={sample.id}
                    defaultChecked={false}
                    checked={_.includes(sampleRequests, sample.id)}
                    value={sample.id}
                    onChange={(e) => {
                      handleChange(sample.id, e.currentTarget.checked)
                    }}
                  >
                    <div className="sample-name">{sample.name}</div>
                    {sample.description && <div>{sample.description}</div>}
                  </Checkbox>
                )
              })}
            </div>
          )
        })}
      </ModalBody>

      <ModalFooter
        cancellable={true}
        onAcceptText={`${t('send')} ${
          sampleRequests.length ? '(' + sampleRequests.length + ')' : ''
        }`}
        onAccept={sendSampleRequest}
        onCancel={clearSampleRequestForm}
        disabled={!sampleRequests.length}
      />
    </Modal>
  )
}

export default RequestSamplesModal
